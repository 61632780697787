<template>
    <div class="supervise">
        <full-scroll></full-scroll>
        <div
            class="
                msg-title
                flex flex-justify-between flex-pack-center
                color_FF
            "
        >
            <div class="flex flex-pack-center flex-1">
                <img
                    src="@/assets/national_emblem.png"
                    alt=""
                    class="msg-national m_r20"
                />
                <div class="text_align_last">
                    <p class="font-size24 m_b10">广阳镇人大代表之家</p>
                    <p class="font-size12">
                        GUANG YANG ZHEN REN DA DAI BIAO ZHI JIA
                    </p>
                </div>
            </div>
            <div class="msg-title-mid-box font-size32 font-blod flex-1">
                <img src="@/assets/images/tot-top-title.png" alt="" />
                <div class="msg-title-mid-box-title">履职风采</div>
            </div>
            <div class="flex-1 flex-end flex" @click="backHome">
                <div class="my_text_center">
                    <img
                        src="@/assets/images/backhome-new.png"
                        alt=""
                        class="msg-backhome m_b10"
                    />
                    <p>返回首页</p>
                </div>
            </div>
        </div>

        <div class="msg-mid flex flex-v flex-justify-center flex-pack-center">
            <div class="feedback-box flex flex-justify-between">
                <div class="feedback-left">
                    <p
                        class="
                            font-size28 font-blod
                            feedback-left-tot-title
                            color_FF
                            my_text_center
                            m_b10
                        "
                    >
                        履职风采
                    </p>
                    <div class="font-size24 feedback-left-tot-box">
                        <ul>
                            <li
                                class="
                                    feedback-list
                                    flex flex-justify-between flex-pack-center
                                    font-blod font-size18
                                "
                                :class="isActived == index ? 'actived' : ''"
                                v-for="(item, index) in superviseType"
                                :key="index"
                                @click="changeSuperviseType(index, item.id)"
                            >
                                <div class="flex flex-pack-center">
                                    <div
                                        class="feedback-list-oricle"
                                        :class="
                                            isActived == index
                                                ? ''
                                                : 'hideFeeback'
                                        "
                                    ></div>
                                    {{ item.label }}
                                </div>
                                <div
                                    v-if="isActived == index"
                                    :class="
                                        isActived == index ? '' : 'hideFeeback'
                                    "
                                >
                                    >
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="feedback-right">
                    <div class="feedback-right-box" id="notice-box-list">
                        <ul>
                            <van-list
                                v-model="loading"
                                :finished="finished"
                                finished-text="没有更多了"
                                @load="onLoad"
                            >
                                <li
                                    class="
                                        font-size24
                                        m_b5
                                        feedback-right-list
                                        flex
                                        flex-justify-between
                                        flex-pack-center
                                    "
                                    v-for="(item, id) in superviseList"
                                    :key="id"
                                >
                                    <div class="flex color_FF m_r20 flex-1">
                                        <div
                                            class="m_r20"
                                            v-if="item.cover != '' && item.cover != null"
                                        >
                                            <img
                                                :src="item.cover"
                                                alt=""
                                                class="duty-pic-img"
                                            />
                                        </div>
                                        <div
                                            class="
                                                m_r20
                                                feedback-list-video-box
                                                flex
                                                flex-justify-center
                                                flex-pack-center
                                            "
                                            v-if="item.video != '' && item.video != null"
                                        >
                                            <img
                                                src="@/assets/play-icon.png"
                                                class="feedback-list-video-icon"
                                                alt=""
                                            />
                                            <video
                                                :src="item.video + '#t=2'"
                                                alt=""
                                                class=""
                                            ></video>
                                        </div>
                                        <div
                                            class="
                                                flex flex-v flex-justify-center
                                            "
                                        >
                                            <p
                                                class="
                                                    font-size24 font-blod
                                                    m_b30
                                                "
                                            >
                                                {{ item.title }}
                                            </p>
                                            <div
                                                class="
                                                    feedback-right-list-i
                                                    color_9FB
                                                    font-size18
                                                    m_b20
                                                "
                                            ></div>
                                            <p class="color_9FB font-size18">
                                                {{ item.releaseTime }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="color_FF font-size18">
                                        <span
                                            class="supervise-check"
                                            @click="goToDetail(item)"
                                            >查看详情</span
                                        >
                                    </div>
                                </li>
                            </van-list>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="
                msg-bottom
                flex flex-justify-between flex-pack-center
                font-size28
            "
        >
            <div class="my_text_center" @click="goBack">
                <img
                    src="@/assets/images/return-new.png"
                    alt=""
                    class="msg-return m_b10"
                />
                <p class="font-size24 color_FF">返回上级</p>
            </div>
            <div class="font-blod my_text_center color_FF"></div>

            <div class="my_text_center color_FF">
                <p class="font-size24 m_b10 font-blod">{{ todayMtime }}</p>
                <p class="font-size14">{{ todayTime }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { getNewsList, getNewsTopics } from "@/request/api.js";
import { dateTimeFormat } from "@/request/until.js";
import fullScroll from "@/components/fullScroll.vue";

export default {
    components: { fullScroll },
    data() {
        return {
            todayMtime: "",
            todayTime: "",
            isActived: 0,
            loading: false,
            finished: false,
            totalPage: null,
            pageNum: 1,
            pageSize: 6,
            tid: null,
            isShowSonList: 1, //是否展示子专题新闻列表
            superviseList: [],
            superviseType: [],
            scrollTop: 0,
        };
    },
    created() {
        this.getTime();
    },
    mounted() {
        this.getNewsTopicsList();
        this.timer = setInterval(() => {
            this.getTime();
        }, 1000);
    },
    activated() {
        document.getElementById("notice-box-list").scrollTop = this.scrollTop; // 设置滚动条位置
    },

    methods: {
        getTime() {
            let _this = this;
            let time = "";
            time = dateTimeFormat(new Date());
            _this.todayTime = time.split(" ")[0];
            _this.todayMtime = time.split(" ")[1].slice(0, 5);
        },

        getNewsTopicsList() {
            let that = this;
            getNewsTopics({ pid: 11 }).then((res) => {
                let data = res.data;
                data.forEach((item) => {
                    that.superviseType.push({ id: item.id, label: item.name });
                });
                that.tid = that.superviseType[0].id;
                that.getFetch();
            });
        },

        changeSuperviseType(index, id) {
            // if (index == 0) {
            //     this.isShowSonList = 1;
            // } else {

            // }
            this.isShowSonList = 2;
            this.isActived = index;
            this.tid = id;
            this.pageNum = 1;
            this.superviseList = [];
            this.getFetch();
        },
        getFetch() {
            getNewsList({
                tid: this.tid,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                status: 1,
                isShowSon: this.isShowSonList,
            }).then((res) => {
                this.loading = false;
                if (res.code == 200) {
                    let data = res.data.list;
                    if (data.length == 0) {
                        this.finished = true;
                    } else {
                        if (this.pageNum == res.data.totalPage) {
                            this.finished = true;
                        } else {
                            this.finished = false;
                        }
                        data.forEach((item) => {
                            item.releaseTime = item.releaseTime.split(" ")[0];
                        });
                        this.totalPage = res.data.totalPage;
                        this.superviseList.push(...data);
                    }
                }
            });
        },

        onLoad() {
            if (this.totalPage <= this.pageNum) {
                this.loading = false;
                this.finished = true;
            } else {
                this.loading = true;
                this.pageNum++;
                this.getFetch();
            }
        },

        goToDetail(data) {
            this.scrollTop =
                document.getElementById("notice-box-list").scrollTop;
            let Data = encodeURIComponent(JSON.stringify(data));
            this.$router.push({
                name: "supervisedetail",
                params: { data: Data, type: 6 },
            });
        },

        //返回首页
        backHome() {
            this.$router.push("/");
        },
        goBack() {
            this.$router.back();
        },
    },
    beforeRouteLeave(to, from, next) {
        if (to.path === "/supervisedetail") {
            from.meta.keepAlive = true;
        } else {
            from.meta.keepAlive = false;
        }
        next();
    },
};
</script>
